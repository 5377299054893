<template>
  <div class="container--boxed">
    <a href="https://skiklub-oestertal.de" class="cursor: pointer;">
      <i class="arrow left icon" />
      <strong>Webseite</strong>
    </a>
    <h1 class="headline">Anmelden</h1>
    <p>
      Bitte melde dich mit deinen Zugangsdaten an, um auf das Backend zugreifen
      zu können.
    </p>
    <form class="ui form" v-on:submit.prevent="login">
      <div class="field required">
        <label>Deine E-Mail-Adresse</label>
        <input
          v-model="credentials.email"
          placeholder="max.muster@email.de"
          type="email"
          name="email"
          autocomplete="email"
          spellcheck="false"
          autocorrect="off"
          autocapitalize="none"
          minlength="3"
          :disabled="loading"
          required
          @input="error = null"
        />
      </div>
      <div class="field required">
        <label>Dein Passwort</label>
        <input
          v-model="credentials.password"
          placeholder="********"
          type="password"
          name="password"
          autocomplete="current-password"
          spellcheck="false"
          autocorrect="off"
          autocapitalize="none"
          minlength="3"
          :disabled="loading"
          required
          @input="error = null"
        />
      </div>
      <div v-if="error" class="ui red message">
        <div class="header">Anmeldung fehlgeschlagen</div>
        <p>{{ error }}</p>
      </div>
      <button
        class="ui fluid right labeled icon primary button large btn-submit"
        :class="{ loading }"
      >
        <i class="right arrow icon" />Anmelden
      </button>
    </form>
    <router-link :to="{ name: 'recover-password' }">
      <div
        class="ui aligned center"
        style="margin-top: 1.25em; text-align: center"
      />
      Passwort vergessen?
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "@firebase/auth";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      error: null,
      credentials: {
        email: null,
        password: null,
      },
    };
  },
  created() {
    // Prefill email address
    if (this.$route.query.email) {
      this.credentials.email = this.$route.query.email;
    }
  },
  mounted() {
    // Prefill email address and focus password field
    if (this.$route.query.email) {
      this.credentials.email = this.$route.query.email;
      document.getElementById("password").focus();
    }
  },
  computed: {
    ...mapGetters({
      user: "user/current",
    }),
  },
  methods: {
    login() {
      this.loading = true;
      this.error = null;
      const self = this;

      this.$store
        .dispatch("user/loginAdminWithEmail", this.credentials)
        .then(() => self.$router.push({ name: "posts" }))
        .catch((err) => {
          if (err.code === "auth/wrong-password") {
            this.error =
              "Die Kombination aus E-Mail-Adresse und Passwort ist falsch. Bitte prüfe deine Angaben erneut oder nutze die „Passwort vergessen”-Funktion!";
          } else if (err.code === "auth/user-not-found") {
            this.error =
              "Für diese E-Mail-Adresse ist noch kein Zugang hinterlegt.";
          } else if (err.code === "auth/no-permission") {
            this.error =
              "Du hast keine Berechtigung die Internetseite über das Backend zu verwalten.";
          } else {
            this.error = err.message;
          }
          this.loading = false;
        });
    },
  },
};
</script>

<style>
.container--boxed {
  margin: 0 auto !important;
  max-width: 400px !important;
}
</style>
